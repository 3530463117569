.background-image {
  height: $full-height;
  background-image: url(../../images/background.jpg);
  background-size: cover;
}
.background-dark {
  background-color: $dark-grey;
}
.full-height {
  height: $full-height;
}
.logo {
  max-width: 12em;
  margin: auto;
}
.logoBlock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.welcome-title {
  color: $super-light-grey;
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: center;
}

.welcome-subtitle {
  color: $gray-500;
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: center;
  font-family: $text-light;
}

@media only screen and (max-width: 1024px) {
  .logo {
    max-width: 8em;
    margin: auto;
  }
}
