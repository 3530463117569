.img-box {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}
.service-image {
  max-height: 50px;
  margin: 0 auto;
}

.service-image-big {
  max-height: 500px;
}
