@import './vars/typography';
@import './vars/fonts';

@import './vars/colors';
@import './vars/layout';

@import '~bootstrap/scss/bootstrap';

@import './base.scss';

@import './components/header';
@import './components/navigation';
@import './components/welcome';
@import './components/services';
@import './components/team';
@import './components/section';
@import './components/footer';
@import './components/contactForm';
