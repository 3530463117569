.section-height {
  min-height: 50vh;
}

.section-dark {
  background-color: $dark-grey;
  color: $white;
}
.no-padding-margin {
  padding: 0;
  margin: 0;
}
