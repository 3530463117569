// Fonts
@import '../vars/fonts';
@import '../vars/typography';
@import '../vars/colors';

//Headings
h1 {
  font-family: $text-bold, sans-serif;
  font-size: 2.5em;
  text-shadow: 0px 3px 6px $shadow-color;
  text-transform: uppercase;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  text-align: center;
}

h2 {
  font-family: $text-bold, sans-serif;
  font-size: 1.5em;
  text-shadow: 0px 3px 6px $shadow-color;
  margin-bottom: 2rem;
}

h3 {
  font-family: $text-bold, sans-serif;
  font-size: 21px;
  line-height: 21px;
  margin-bottom: 2rem;
}

h4 {
  font-family: $text-bold, sans-serif;
  font-size: 18px;
}

h5 {
  font-family: $text-bold, sans-serif;
  font-size: 14px;
}

h6 {
  font-family: $text-bold, sans-serif;
  font-size: 12px;
}
