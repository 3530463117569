@import '../vars/colors';

.btn-primary {
  width: 304px;
  height: 78px;
  background-color: $light-grey;
  color: $black;
  border-color: $light-grey;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: $blue-grey;
}

.btn-primary:disabled {
  background-color: transparent;
  color: $black;
  border-color: $light-grey;
}
